<template>
    <Card>
        <div slot="title">{{ title }}</div>
        <!-- <Card v-if="!isEdit" title="账号信息" dis-hover>
            <div class="form_item">
                <div class="form_label must_input">账号昵称</div>
                <Input class="width_300" v-model.trim="formData.nickName" :maxlength="15" show-word-limit />
            </div>
            <div class="form_item">
                <div class="form_label must_input">账号</div>
                <Input class="width_300" v-model.trim="formData.userPhone" :maxlength="15" show-word-limit></Input>
            </div>
            <div class="form_item">
                <div class="form_label must_input">密码</div>
                <Input class="width_300" type="password" v-model.trim="formData.passWord" password></Input>
            </div>
            <div class="form_item">
                <div class="form_label must_input">角色选择</div>
                <Select class="width_300" v-model="formData.groupId">
                    <Option v-for="info in roleList" :key="info.id" :value="info.id">{{ info.groupname }}</Option>
                </Select>
            </div>
        </Card> -->
        <div @click="toRule" style="position: absolute;
    top: 10px;
    left: 181px;cursor: pointer;color: #57a3f3;font-size: 15px;"><Icon type="ios-paper" />规则说明</div>
        <Card title="省级代理商规则设定" dis-hover>
            <div class="form_item">
                <div class="form_label must_input">默认分佣比例</div>
                <div v-if="isDetail == 'true'">{{ formData.province_rate }}</div>
                <InputNumber :max="100" v-model="formData.province_rate" :formatter="value => `${value}%`" :parser="value => value.replace('%', '')" />
            </div>
            <div class="form_item">
                <div class="form_label must_input">分佣范围</div>
                <div style="display: flex">
                    <InputNumber :max="100" v-model="value5" :formatter="value => `${value}%`" :parser="value => value.replace('%', '')" />
                    ~
                    <InputNumber :max="100" v-model="value6" :formatter="value => `${value}%`" :parser="value => value.replace('%', '')" />
                </div>
            </div>
        </Card>
        <Card title="二级经销商规则设定" dis-hover>
            <div class="form_item">
                <div class="form_label must_input">默认分佣比例</div>
                <div v-if="isDetail == 'true'">{{ formData.dealer_rate }}</div>
                <InputNumber :max="100" v-model="formData.dealer_rate" :formatter="value => `${value}%`" :parser="value => value.replace('%', '')" />
            </div>
            <div class="form_item">
                <div class="form_label must_input">分佣范围</div>
                <div style="display: flex">
                    <InputNumber :max="100" v-model="value1" :formatter="value => `${value}%`" :parser="value => value.replace('%', '')" />
                    ~
                    <InputNumber :max="100" v-model="value2" :formatter="value => `${value}%`" :parser="value => value.replace('%', '')" />
                </div>
            </div>
            <!-- <div class="form_item">
                <div class="form_label must_input">比例是否可超出</div>
                <RadioGroup v-model="formData.dealer_over_allow" type="button" button-style="solid">
                    <Radio label="1">是</Radio>
                    <Radio label="0">否</Radio>
                </RadioGroup>
            </div>
            <div class="form_item">
                <div class="form_label must_input">超出审核单位</div>
                <Select v-model="formData.dealer_over_exam" style="width: 200px">
                    <Option v-for="item in ccunitList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                </Select>
            </div>
            <div class="form_item">
                <div class="form_label must_input">经销商数量</div>
                <Select v-model="formData.dealer_limit" style="width: 200px">
                    <Option v-for="item in numList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                </Select>
            </div> -->
        </Card>
        <Card title="三级个人\小B端规则设定" dis-hover>
            <div class="form_item">
                <div class="form_label must_input">默认分佣比例</div>
                <div v-if="isDetail == 'true'">{{ formData.person_rate }}</div>
                <InputNumber :max="100" v-model="formData.person_rate" :formatter="value => `${value}%`" :parser="value => value.replace('%', '')" />
            </div>
            <div class="form_item">
                <div class="form_label must_input">分佣范围</div>
                <div style="display: flex">
                    <InputNumber :max="100" v-model="value3" :formatter="value => `${value}%`" :parser="value => value.replace('%', '')" />
                    ~
                    <InputNumber :max="100" v-model="value4" :formatter="value => `${value}%`" :parser="value => value.replace('%', '')" />
                </div>
            </div>
            <!-- <div class="form_item">
                <div class="form_label must_input">比例是否可超出</div>
                <RadioGroup v-model="formData.person_over_allow" type="button" button-style="solid">
                    <Radio label="1">是</Radio>
                    <Radio label="0">否</Radio>
                </RadioGroup>
            </div>
            <div class="form_item">
                <div class="form_label must_input">超出审核单位</div>
                <Select v-model="formData.person_over_exam" style="width: 200px">
                    <Option v-for="item in cczunitList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                </Select>
            </div>
            <div class="form_item">
                <div class="form_label must_input">经销商数量</div>
                <Select v-model="formData.person_limit" style="width: 200px">
                    <Option v-for="item in numList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                </Select>
            </div> -->
        </Card>
        <div class="mt_50 flex align_center justify_center form_item">
            <i-button type="primary" ghost @click="onCloseEdit">返回</i-button>
            <i-button class="ml_50" type="success" :loading="ajaxLoading" @click="onConfirm">确定</i-button>
        </div>
        <Modal :closable="false" v-model="showCreate" title="分销规则说明" width="500" :loading="ajaxLoading">
            <Alert>
                省代理分销规则说明
                <template #desc>省代理的分佣设置为按订单的百分比进行分佣，如订单为800元，省代分佣为50%，则省代理实际分佣为800×50%=400元</template>
            </Alert>
            <Alert type="success">
                经销商分销规则说明
                <template #desc>经销商的分佣设置为省代理的分佣金额的百分比进行分佣，如省代理的分佣为400元，省代给经销商分佣60%，则经销商实际分佣为400×60%=240元</template>
            </Alert>
            <Alert type="warning">
                个人/小B端规则说明
                <template #desc>个人/小B端的分佣设置为经销商的分佣金额的百分比进行分佣，如经销商的分佣为240元，销商商给个人/小B端分佣40%，则经销商实际分佣为240×40%=96元</template>
            </Alert>
            <div slot="footer" class="relative width_100_percent flex align_center justify_center">
                <Button type="primary" ghost @click="showCreate = false">关闭</Button>
                <!-- <Button class="ml_100" type="primary" :loading="ajaxLoading" @click="onConfirmCreate">确定</Button> -->
            </div>
        </Modal>
        <mySpin :loading="ajaxLoading"></mySpin>
    </Card>
</template>
<script>
import { reqGroupList, addAgent, updateAgent, readAgent, saveRule, getRule } from '@/lib/request/auth';
import city from '@/components/city';
import { reqMerchantCreateAccount, reqMerchantSaveAccount } from '@/lib/request/auth2';
import { getCityTitles } from '@/lib/city.data';
import UploadImage from '@/components/uploadImage';

export default {
    name: 'serviceProviderAdd',
    components: {
        city,
        UploadImage,
    },
    data() {
        return {
            value5: '',
            value6: '',
            value1: '1',
            value2: '2',
            value3: '1',
            value4: '2',
            ccunitList: [
                {
                    value: '总部',
                    label: '总部',
                },
                {
                    value: '省代理',
                    label: '省代理',
                },
            ],
            cczunitList: [
                {
                    value: '总部',
                    label: '总部',
                },
                {
                    value: '省代理',
                    label: '省代理',
                },
                {
                    value: '经销商',
                    label: '经销商',
                },
            ],
            numList: [
                {
                    value: '10',
                    label: '10',
                },
                {
                    value: '20',
                    label: '20',
                },
                {
                    value: '50',
                    label: '50',
                },
                {
                    value: '100',
                    label: '100',
                },
                {
                    value: '不限制',
                    label: '不限制',
                },
            ],
            model: '',
            showCreate: false,
            ratevalue: 0,
            fruit: '',
            vertical: '',
            title: '添加题目',
            formData: {
                dealer_over_allow: 0,
                person_over_allow: 0,
            },
            address_code: [],
            address_note: null,
            roleList: [],
            address: [],
            isEdit: false,
            isDetail: false,
            arrPic: [],
        };
    },
    mounted() {
        this.routeParamsInjectFormData(['address_code', 'address_note']);
        this.isEdit = this.$route.query.isEdit;
        this.formData.id = this.$route.query.id;
        this.isDetail = this.$route.query.isDetail;
        console.log(this.$route.query.isEdit, 'this.$route.query.isEdit');
        this.title = this.isEdit == 'true' ? '分销规则设置' : '分销规则设置';
        this.getRoleList();
        this.getDetail();
        this.getruleDetail();
    },
    watch: {
        //
    },
    methods: {
        toRule(){
            this.showCreate = true;
        },
        getruleDetail() {
            getRule().then(res => {
                this.formData = res.data;
                this.value5 = res.data.province_range.split(',')[0];
                this.value6 = res.data.province_range.split(',')[1];
                this.value1 = res.data.dealer_range.split(',')[0];
                this.value2 = res.data.dealer_range.split(',')[1];
                this.value3 = res.data.person_range.split(',')[0];
                this.value4 = res.data.person_range.split(',')[1];
            });
        },
        tabsClick(name) {},
        onConfirmCreate() {},
        onAdd() {
            this.showCreate = true;
        },
        //单个图片上传成功
        onSuccessUploadImg() {
            if (this.arrPic.length < this.maxLength && hm.isNotEmptyString(this.arrPic.slice(-1)[0])) {
                this.arrPic.push('');
            } else if (this.arrPic.length <= 0) {
                this.arrPic.push('');
            }
        },
        //删除某张图片
        onRemoveImg() {
            this.arrPic = this.arrPic.filter(src => !!src);
            this.onSuccessUploadImg();
            this.arrPic.push(0);
            this.arrPic.pop();
        },
        getRoleList() {
            this.ajaxLoading = true;
            reqGroupList({
                groupId: 6,
            })
                .then(res => {
                    let list = res?.data?.list || [];
                    this.roleList = list;
                })
                .finally(res => {
                    this.ajaxLoading = false;
                });
        },
        //获取详情
        getDetail() {
            if (this.isEdit == 'true') {
                this.showAjaxLoading();
                readAgent({ id: this.formData.id })
                    .then(res => {
                        this.formData = res.errmsg;
                        let arr = JSON.parse(this.formData.area);
                        this.formData.areaarr = arr[0];
                        this.address = arr[1];
                        console.log(this.address, 'address');
                        this.dataInjectFormData(res);
                        // this.arrPic = this.formData.pic.split(this.picSeq);
                    })
                    .finally(() => {
                        this.hideAjaxLoading();
                    });
            }
        },
        //取消
        onCloseEdit() {
            this.$router.back();
            setTimeout(() => {
                this.hideAjaxLoading();
            }, 500);
        },
        //确认
        onConfirm() {
            // this.formData.area = this.address_code;
            if (Number(this.value4) > Number(this.value2)) {
                this.fadeWarning('经销商分佣范围比例不能小于个人分佣比例');
                return;
            }
            if (Number(this.value4) > Number(this.value6)) {
                this.fadeWarning('省代理分佣范围比例不能小于其他层级比例');
                return;
            }
            if (Number(this.value2) > Number(this.value6)) {
                this.fadeWarning('省代理分佣范围比例不能小于其他层级比例');
                return;
            }
            this.formData.dealer_range = this.value1 + ',' + this.value2;
            this.formData.person_range = this.value3 + ',' + this.value4;
            this.formData.province_range = this.value5 + ',' + this.value6;

            let params = window.structuredClone(this.formData);
            // let { nickName, userPhone, passWord, groupId, name, userName, userTel } = params || {};
            // let vs = [
            //     { value: nickName, tip: '请输入账号昵称' },
            //     { value: userPhone, tip: '请输入账号' },
            //     { value: passWord, tip: '请输入密码' },
            //     { value: groupId, tip: '请选择角色' },
            //     { value: name, tip: '请输入商务商名称' },
            //     { value: userName, tip: '请输入联系人' },
            //     { value: userTel, tip: '请输入手机号' },
            // ];
            let vs = [];
            hm.validateForm(vs)
                .then(() => {
                    saveRule(params)
                        .then(res => {
                            this.fadeAlert('设置成功');
                        })
                        .catch(() => {
                            this.hideAjaxLoading();
                        });
                })
                .catch(msg => {
                    this.hideAjaxLoading();
                    this.fadeWarning(msg);
                });
        },
        onConfirmSave() {
            console.log(this.formData, 'this.formData');
            let address_code = this.formData.areaarr;
            console.log(getCityTitles(address_code), 'address_title');
            this.showAjaxLoading();
            this.formData.type = 1;
            this.formData.pid = 0;
            let addressObj = [];
            addressObj.push(this.formData.areaarr);
            addressObj.push(getCityTitles(address_code));
            this.formData.area = JSON.stringify(addressObj);
            console.log(this.formData.area, 'this.formData.area');
            let params = window.structuredClone(this.formData);
            // let { nickName, userPhone, passWord, groupId, name, userName, userTel } = params || {};
            // let vs = [
            //     { value: nickName, tip: '请输入账号昵称' },
            //     { value: userPhone, tip: '请输入账号' },
            //     { value: passWord, tip: '请输入密码' },
            //     { value: groupId, tip: '请选择角色' },
            //     { value: name, tip: '请输入商务商名称' },
            //     { value: userName, tip: '请输入联系人' },
            //     { value: userTel, tip: '请输入手机号' },
            // ];
            let vs = [];
            hm.validateForm(vs)
                .then(() => {
                    if (address_code.length <= 0) {
                        this.fadeWarning('请选择公司地址');
                        this.hideAjaxLoading();
                        return;
                    }
                    console.log(address_code, 'address_code');
                    let address_title = getCityTitles(address_code);
                    console.log(address_title, 'address_title');
                    params.address = address_title.join(',') + ';' + this.address_note || '';
                    params.address_code = address_code.join(',');
                    addAgent(params)
                        .then(res => {
                            this.fadeAlert(res.errmsg);

                            this.onCloseEdit();
                        })
                        .catch(() => {
                            this.hideAjaxLoading();
                        });
                })
                .catch(msg => {
                    this.hideAjaxLoading();
                    this.fadeWarning(msg);
                });
        },
        onConfirmEdit() {
            console.log(this.formData, 'this.formData');
            let address_code = this.formData.areaarr;
            console.log(getCityTitles(address_code), 'address_title');
            this.showAjaxLoading();
            this.formData.type = 1;
            this.formData.pid = 0;
            let addressObj = [];
            addressObj.push(this.formData.areaarr);
            addressObj.push(getCityTitles(address_code));
            this.formData.area = JSON.stringify(addressObj);
            console.log(this.formData.area, 'this.formData.area');
            let params = window.structuredClone(this.formData);
            // let { name, userName, userTel } = params || {};
            // let vs = [
            //     { value: name, tip: '请输入商务商名称' },
            //     { value: userName, tip: '请输入联系人' },
            //     { value: userTel, tip: '请输入手机号' },
            // ];
            let vs = [];

            hm.validateForm(vs)
                .then(() => {
                    if (address_code.length <= 0) {
                        this.fadeWarning('请选择公司地址');
                        this.hideAjaxLoading();
                        return;
                    }
                    let address_title = getCityTitles(address_code);
                    params.address = address_title.join(',') + ';' + this.address_note || '';
                    params.address_code = address_code.join(',');
                    updateAgent(params)
                        .then(res => {
                            this.fadeAlert(res.errmsg);

                            this.onCloseEdit();
                        })
                        .catch(() => {
                            this.hideAjaxLoading();
                        });
                })
                .catch(msg => {
                    this.hideAjaxLoading();
                    this.fadeWarning(msg);
                });
        },
    },
};
</script>
<style lang="less" scoped></style>
